const colors = {
  tundora: '#4a4a4a',
  shark: '#212223',
  white: 'white',
  black: 'black',

  sunset: '#f44647',

  text: '#bbb6b7'
};

export { colors };
